<template>
  <v-container>
    <h2>{{$t("message.title-product-detail")}}</h2>
    <div class="container">
      <div class="row">
        <v-col class="pr-2 pb-2" cols="12">
          <v-row no-gutters align="center">
            <v-col cols="12" md="4" xs="2">
              <span class="label">{{$t("message.title-product-detail")}}</span>
            </v-col>
            <v-col cols="12" md="5" xs="2">
              <div style="width: 100%; padding: 5px 0px 5px 0px; height: 100%;">
                <span class="value" style="padding: 7px">{{
                  productDetail.item_name
                }}</span>
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col class="pr-2 pb-2" cols="12">
          <v-row no-gutters align="center">
            <v-col cols="12" md="4" xs="2">
              <span class="label">{{$t("message.label-stock")}}</span>
            </v-col>
            <v-col cols="12" md="5" xs="2">
              <div style="width: 100%; padding: 5px 0px 5px 0px; height: 100%;">
                <span class="value" style="padding: 7px">{{
                  productDetail.stock_total
                }}</span>
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col class="pr-2 pb-2" cols="12">
          <v-row no-gutters align="center">
            <v-col cols="12" md="4" xs="2">
              <span class="label">{{$t("message.label-price")}}</span>
            </v-col>
            <v-col cols="12" md="5" xs="2">
              <div style="width: 100%; padding: 5px 0px 5px 0px; height: 100%;">
                <span class="value" style="padding: 7px">{{
                  moneyFormat(productDetail.price)
                }}</span>
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col class="pr-2 pb-2" cols="12">
          <v-row no-gutters align="center">
            <v-col cols="12" md="4" xs="2">
              <span class="label">{{$t("message.label-description")}}</span>
            </v-col>
            <v-col cols="12" md="5" xs="2">
              <div style="width: 100%; padding: 5px 0px 5px 0px; height: 100%;">
                <span class="value" style="padding: 7px">{{
                  productDetail.description
                }}</span>
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col class="pr-2 pb-2" cols="12">
          <v-row no-gutters align="center">
            <v-col cols="12" md="4" xs="2">
              <span class="label">{{$t("message.label-product-image")}}</span>
            </v-col>
            <v-col cols="12" md="5" xs="2">
              <div style="width: 100%; padding: 5px 0px 5px 0px; height: 100%;">
                <template>
                  <v-container class="d-flex justify-end">
                    <v-row class="mb-6" no-gutters justify="start">
                      
                      <v-col
                        v-for="(img, i) in productDetail.images" :key="i"
                      >
                      <v-img
                      :lazy-src="img == 0 ? notFound : img.image_url"
                      :src="img == 0 ? notFound : img.image_url"
                      max-height="150px"
                      max-width="180px"
                      width="150"
                      class="m-2"
                      ></v-img>
                      </v-col>
                    </v-row>
                  </v-container>
                </template>
              </div>  
            </v-col>
          </v-row>
        </v-col>
      </div>
      <div class="fixed-div">
        <v-row class="d-flex justify-space-between mb-6">
          <v-col>
            <v-btn
              rounded
              color="secondary"
              dark
              style="float:start"
              @click.prevent="goBack"
            >
              {{$t("message.btn-back")}}
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </div>
  </v-container>
</template>

<script>
import moneyFormat from '../helpers/moneyFormat'
export default {
  name: "Productdetail",
  data() {
    return {
      notFound: require("../assets/image-not-found.png"),
    };
  },
  computed: {
    productDetail() {
      return this.$store.state.product_module.productDetailResult;
    },
  },
  created() {
    this.dispatchProductDetail();
  },
  methods: {
    moneyFormat(money) {
      return moneyFormat(money)
    },
    dispatchProductDetail() {
      let slug = this.$router.history.current.params.slug;
      this.$store.dispatch("product_module/fetchProductDetail", slug);
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
@media only screen and (max-width: 640px) {
  .nested-label-col-2 {
    padding-top: 0;
    padding-bottom: 0;
    width: 200vw;
    margin-left: 17px;
  }
  .nested-label-col-3 {
    padding-top: 0;
    padding-bottom: 0;
  }
}
.label {
  text-transform: capitalize;
}
.label-title {
  text-transform: capitalize;
  color: #3f51b5;
  font-weight: bold;
}

.container {
  display: flex;
  flex-direction: column;
  /* border: 1px solid; */
  height: 100%;
}

.fixed-div {
  margin-top: auto;
  bottom: 0px;
  right: 50%;
}
</style>
